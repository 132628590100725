export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img;
        if (this.props.image) {
            img = (
                <div className="pict">
                    <img src={this.props.image} alt="" />
                </div>
            );
        }

        return (
            <div className="list-item">
                <div className="date">
                    <p>{this.props.date}</p>
                </div>
                {img}
                <div className="caption">
                    <a href={this.props.url}>{this.props.caption}</a>
                </div>
                <div className="annotation">
                    <p>{this.props.annotation}</p>
                </div>
                <div
                    className="model-item-placeholder"
                    data-type="ratings"
                    data-model-name="news-service-item"
                    data-model-id={this.props.id}
                    data-params="null"
                >
                    <span className="rates-container">
                        <span className="rates">
                            <span className="rate-stars">
                                <span className="result-layer"></span>
                                <span className="visitor"></span>
                            </span>
                        </span>
                    </span>
                </div>
                <div className="clear"></div>
            </div>
        );
    }
}
