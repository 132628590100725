export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="event-item">
                <div className="event-data">
                    <div className="event-logo news"></div>
                    <div className="event-info-container">
                        <span className="event-date">{this.props.date}</span>
                        <span className="events-type">{this.props.note}</span>
                        <div className="events-caption">
                            <div className="links-wrap">
                                <a href={this.props.url}>
                                    {this.props.caption}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href={this.props.url} className="links">
                    К публикации
                </a>
            </div>
        );
    }
}
