import * as Store from "../../store.js";
import * as Repository from "../../../components/repositories/news-list-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse } from "../helpers/request-helper.js";
import { request } from "../../../components/repositories/repository.js";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export function loadLayout(params) {
    params = params || {};
    return handleResponse(Repository.getLayout(params), (response) => {
        Store.setStore("data", response.data);
        Store.setStore("data.list", prepareList(response.data.list));
        if (response.data.cms && response.data.cms.actions) {
            Store.setStore(
                "data.cms.actions",
                prepareCmsActions(response.data.cms.actions)
            );
        }
        return true;
    });
}

export function loadMoreList(params) {
    if (!params.url) {
        return Promise.reject();
    }

    return handleResponse(request("GET", params.url), (response) => {
        const list = getNestedValue(response, "data", "list");
        const loadMore = getNestedValue(response, "data", "loadMore");
        Store.addList("data.list", prepareList(list));
        Store.setStore("data.loadMore", loadMore);
        Store.triggerUpdate("layout");
        return true;
    });
}

/* HELPERS */

function prepareList(list) {
    if (!list || !list.length) {
        return list;
    }

    const newsItemBaseUrl = Store.getStore("config.newsItemBaseUrl");

    list.forEach((item, index) => {
        // надо поменять ссылку, т.к. сейчас она ведет на сервис новостей
        // а нам нужна ссылка на страницу сайта
        if (newsItemBaseUrl) {
            list[index].url = prepareItemUrl(item, newsItemBaseUrl);
        }
        if (item.cms && item.cms.actions) {
            list[index].cms.actions = prepareCmsActions(item.cms.actions);
        }
    });

    return list;
}

function prepareCmsActions(actions) {
    const editor = Store.getStore("config.editor");
    if (!editor) {
        return actions;
    }

    actions.forEach((action, index) => {
        actions[index].url += "&editor=" + editor;
    });

    return actions;
}

function prepareItemUrl(item, baseUrl) {
    var queryString = item.url.split("?").pop();
    return baseUrl + "?" + queryString;
}
