import { request, config } from "./repository.js";

export function getLayout(params) {
    return request("get", config.newsListUrl, null, {
        credentials: "include",
    });
}

export function getDates(params) {
    let url = config.newsDatesUrl;

    if (params) {
        for (var key in params) {
            url += "&" + key + "=" + params[key];
        }
    }
    return request("get", url, null, {
        credentials: "include",
    });
}
