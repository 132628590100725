import ListItem from "./list-item.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                list.push(<ListItem key={key} {...item} />);
            });
        }

        return <div className="ns-news-list-layout">{list}</div>;
    }
}
