export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props);
        return (
            <a className="event-item" href={this.props.url}>
                <span className="events-type">{this.props.note}</span>
                <div className="events-caption">
                    <div className="links-wrap">
                        <p>{this.props.caption}</p>
                        <span className="links"></span>
                    </div>
                </div>
            </a>
        );
    }
}
