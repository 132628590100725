export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img;
        if (this.props.image) {
            img = (
                <div className="image-prev">
                    <div className="image">
                        <img
                            src={this.props.image}
                            alt=""
                            className="img-prev"
                        />
                    </div>
                </div>
            );
        } else {
            img = (
                <div className="image-prev without-popup">
                    <div className="no-image news"></div>
                </div>
            );
        }

        return (
            <div className="event-item news">
                <div className="event-data">
                    {img}
                    <div className="events-caption">
                        <span className="events-type">{this.props.note}</span>
                        <h3>{this.props.caption}</h3>
                        <span>
                            <a href={this.props.url} className="links">
                                Подробнее
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
